"use strict"

class VueSelectCompat {
    static adapterForData(items, page_size) {
        if(this.select2ArrayAdapter) {
            return class extends this.select2ArrayAdapter {
                current(cb) {
                    return cb(items.filter(i => i.selected))
                }
                query(params, callback) {
                    if(!params.page) {
                        params.page = 1;
                    }
                    let visible_items = params.term ?
                        items.filter(
                            item => item.text.match(params.term)
                        ) :
                        items
                    callback({
                        results: visible_items.slice(
                            (params.page - 1) * page_size,
                            params.page * page_size
                        ),
                        pagination: {
                            more: params.page * page_size < visible_items.length,
                        },
                    })
                }
            }
        } else {
            return null
        }
    }
}
VueSelectCompat.select2ArrayAdapter = null

let VueSelect = Vue.extend({
    template: '<select '+
        'class="direct-select2" ' +
        'v-bind:name="name" ' +
        'v-bind:multiple="multiple" ' +
        'v-bind:data-placeholder="label" ' +
        'v-bind:data-searchable="searchable" ' +
        'style="width: 100%" ' +
        '>' +
        '</select>',
    name: "vueSelect",
    props: {
        ajax: {
            type: Object,
            required: false,
        },
        closeOnSelect: {
            type: Boolean,
            default: true,
        },
        infiniteScroll: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
        },
        model: {
            type: [String, Number, Array],
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String
        },
        options: {
            type: Array,
            default() {
                return []
            },
        },
        searchable: {
            type: Boolean,
            required: false,
            default: true,
        },
        templateResult: {
            type: Function,
            required: false,
        },
        templateSelection: {
            type: Function,
            required: false,
        },
        dropdownCssClass: {
            type: String,
            required: false,
            default: ""
        }
    },
    data() {
        return {
            saneOptions: null,
        }
    },
    mounted() {
        this.reinit()
        $(this.$el)
            .on("change", function(evt) {
                if(this._events && !this._events["update:model"]) {
                    console.warn(
                        "Model update is not being tracked, please add .sync or an explicit event handler"
                    )
                }
                if(this.multiple) {
                    let selected = []
                    for(var i = 0; i < evt.currentTarget.selectedOptions.length; i++) {
                        selected.push(evt.currentTarget.selectedOptions[i].value)
                    }
                    this.$emit('update:model', selected)
                } else {
                    this.$emit('update:model', evt.currentTarget.value)
                }
            }.bind(this));
    },
    beforeDestroy() {
        $(this.$el).select2("destroy");
    },
    methods: {
        reinit() {
            this.updateSaneOptions()
            let adapter = (this.infiniteScroll && !this.ajax) ?
                VueSelectCompat.adapterForData(this.saneOptions, 50) :
                null
            $(this.$el)
                .empty()
                .select2({
                    data: (adapter || this.ajax) ? null : this.saneOptions,
                    ajax: adapter ? {} : this.ajax,
                    closeOnSelect: this.closeOnSelect,
                    dataAdapter: adapter,
                    minimumResultsForSearch: this.searchable ? 0 : Infinity,
                    templateResult: this.templateResult,
                    templateSelection: this.templateSelection,
                    dropdownCssClass: this.dropdownCssClass
                })
            if(this.infiniteScroll) {
                $(this.$el)
                    .trigger("change.select2")
            } else {
                $(this.$el)
                    .val(this.model)
                    .trigger("change")
            }
        },
        updateSaneOptions() {
            if(this.options.length == 0) {
                this.saneOptions = [];
            } else {
                if(
                    (typeof this.options[0] == "object") ||
                    (typeof this.options[0] == "function")
                ) {
                    if(this.options[0].hasOwnProperty("value")) {
                        console.warn(
                            "Options have been supplied in the Select2 1.x format (value, text). To work in future they will need to be updated to Select2 4.x format (id, text)"
                        )
                        this.saneOptions = this.options.map(o => ({
                            id: o.value,
                            text: o.text,
                            selected: this.multiple ?
                                this.model.includes(o.value) :
                                o.value == this.model,
                        }))
                    } else {
                        this.saneOptions = this.options.map(
                            o => Object.assign(
                                {},
                                o,
                                {
                                    selected: this.multiple ?
                                        this.model.includes(o.id) :
                                        o.id == this.model,
                                }
                            )
                        )
                    }
                } else {
                    this.saneOptions = this.options.map(o => ({
                        id: o,
                        text: o,
                        selected: this.multiple ?
                            this.model.includes(o) :
                            o == this.model,
                    }))
                }
            }
        }
    },
    watch: {
        model(v, o) {
            // NOTE: for multi-select, select2 sends the whole array as the
            // value, but Vue's change detection detects that as "whole thing
            // changed" each time, and setting the value ALSO triggers a model
            // update.
            if(this.infiniteScroll) {
                if(v !== o) {
                    if(this.multiple) {
                        this.saneOptions.forEach(
                            opt => opt.selected = this.model.includes(opt.id) ? true : null
                        )
                    } else {
                        this.saneOptions.forEach(
                            opt => opt.selected = opt.id == v ? true : null
                        )
                    }
                }
                $(this.$el).trigger("change.select2")
            } else if(
                !this.multiple || JSON.stringify(v) !== JSON.stringify(o)
            ) {
                $(this.$el)
                    .val(v)
                    .trigger("change")
            }
        },
        options(v, old) {
            if(
                v.length == old.length &&
                v.length < 256 &&
                JSON.stringify(v) == JSON.stringify(old)
            ) {
                console.log("No change")
            } else {
                this.reinit()
            }
        }
    }
})
$.fn.select2.amd.require(
    ["select2/data/array"],
    ArrayData => VueSelectCompat.select2ArrayAdapter = ArrayData
)